<template>
  <div class="wrapper">
    <component
    :is="hybridAuth"
       v-if="!subscriberid"
        :formInfo="authInfo"
        :closePopup="() => closeAuthPopup(false)"
    ></component>
    <div
      v-if="!success && subscriberid"
      class="container pin-pair-container"
      :class="{
        'container-ara': seasonUiFlip === 'ml' ? true : false,
        'container-default': seasonUiFlip !== 'ml' ? true : false,
      }"
    >
      <div class="tv-logo" :class="localDisplayLang === 'ml' ? 'ml' : ''">
        <img
          src="@/assets/icons/pin-pairing-logo.svg"
          alt="ultra_logo"
          class="noorPlay_tv_logo"
        />
        <img
          src="@/assets/icons/pin-mobile.svg"
          alt="ultra_logo"
          class="noorPlay_logo_pin"
        />
      </div>
      <h1>{{ $t("Enter the pin Generated on your TV") }}</h1>
      <div class="pin-table-form">
        <div id="pininputs" class="profile-popup-pin-box-inputs">
          <input
            v-for="(item, index) in 6"
            :key="index"
            class="pin"
            type="tel"
            v-model="userPin[item - 1]"
            @input="grabPin"
            maxlength="1"
            inputmode="numeric"
          />
        </div>
        <h2 class="validation-message" v-if="invalidPin">
          {{ $t(errorMessage) }}
        </h2>
        <h2 class="validation-message" v-if="loadingState">
          {{ $t("loading") }}...
        </h2>
      </div>
      <div class="container-1 btn-container">
        <button
          id="button1"
          class="loginWithEmail button-primary primary-net5-button"
          v-on:click="pinVerify"
        >
          {{ $t("continue") }}
        </button>
      </div>
    </div>
    <transition>
      <AuthPopup
        v-if="!subscriberid"
        :formInfo="authInfo"
        :closePopup="() => closeAuthPopup(false)"
      />
    </transition>
    <SuccessModalNew
      v-if="success"
      id="pc-pin-modal"
      :open="showPinModal"
      @onClose="closePinModal"
      @onActionComplete="onPinModalActionComplete"
    />
  </div>
</template>

<script>
import Utility from "@/mixins/Utility.js";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {
    SuccessModalNew: () => import("@/components/utils/SuccessModalNew.vue"),
  },
  mixins: [Utility],
  name: "LoginWithPinView",

  data() {
    return {
     
      input: {
        code: [],
      },
      hybridAuth: "",
      currentIndex: 0,
      enteredPinCode: null,
      invalidPin: false,
      resData: null,
      errorMessage: "",
      loadingState: false,
      configClass: null,
      displayLang: null,
      showModal: false,
      countryName: null,
      amount: 6,
      userPin: [],
      success: false,
      profilePin: "",
      authInfo: {},
    };
  },

  computed: {
    ...mapGetters(["subscriberid", "getPairingFlag","appConfig"]),
  },
  mounted() {
    if(!this.subscriberid) {
      window.storeAfterLoginPath = this.$route.path;
    }
    this.setPairingFlag(true);
    this.authInfo.formType = "lookup";
    this.setupPin();

    this.hybridAuth = this.appConfig.featureEnabled.useNewAuthFlow ? () => import(
        /* webpackChunkName: "header" */ "@/components/Popups/AuthenticationNew/userAuthNew.vue"
      ) : () => import(
        /* webpackChunkName: "header" */ "@/components/Popups/AuthenticationOld/userAuthOld.vue"
      )
  },

  directives: {
    focus: {
      componentUpdated: function(el, obj) {
        obj.value && el.focus();
      },
    },
  },

  created() {
    this.code = new Array(this.amount).fill("");
  },

  methods: {
    ...mapMutations(["setPairingFlag"]),
    ...mapActions(["pairDevice"]),
    setupPin() {
      setTimeout(() => {
        this.getId = document.getElementById("pininputs");
        this.pinFunctionality(this.getId);
      }, 1000);
    },
		clickMethod(){
			this.$router.push({ path: "/tv" });
		},
    closeAuthPopup(state) {
      this.$router.replace({
        name: "Home",
      });
      // this.$router.push({ name: "Home" });
    },
    setupPin() {
      setTimeout(() => {
        this.getId = document.getElementById("pininputs");
        if (this.getId) {
          this.pinFunctionality(this.getId);
        }
      }, 1000);
    },

    // Fetch the pins as the user user enter in the input.
    grabPin() {
      this.errorMessage = "";
      if (this.userPin.length > 3) {
        let pin = "";
        this.userPin.forEach((element) => {
          pin += element;
        });
        this.profilePin = pin;
      }
    },
    pinVerify() {
      if (!this.profilePin) {
        this.errorMessage = "Please enter the Pin";
        this.invalidPin = true;
        return;
      }
      let inputEntered = this.input.code.join("");
      const profilePayload = {
        pin: this.profilePin,
      };

      this.pairDevice(profilePayload)
        .then((response) => {
          if (!response.data.reason) {
            this.success = true;
            this.showPinModal = true;
          } else {
            // this.success = true;
            // this.showPinModal = true;
            this.errorMessage = response.data.reason;
            this.invalidPin = true;

            this.userPin = [];
            this.profilePin = "";

            //showSnackBar(this.$t(response.data.reason));
          }
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    onDelete(e, index) {
      if (e.target.value === "") {
        this.currentIndex = index - 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./LoginWithPinView.scss"
</style>
