<template>
    <div @click.stop="actPlayContent(content)" :class="[whereIamFrom === 'episode' ? 'h-w-100' : '']" :id="`play_button_${content && content.objectid ? content.objectid : ''}`">
        <div v-if="whereIamFrom === 'hoverCard'">
            <svg width="46" height="46" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42ZM14 13.6773V30.3644C14 31.112 14.7904 31.5953 15.4558 31.2545L31.3086 23.1364C32.0251 22.7695 32.0365 21.7495 31.3283 21.3666L15.4755 12.7976C14.8092 12.4374 14 12.9199 14 13.6773Z" fill="white"/>
            </svg>   
        </div>   
        <div v-if="whereIamFrom === 'featured'" style="display: flex;gap: 0.3rem;justify-content: center;align-items: center;" class="featured_play" >
            <svg width="14" height="18" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.527832 19.8555V1.96396C0.527832 1.57132 0.66093 1.25462 0.927124 1.01387C1.19332 0.773123 1.50235 0.652748 1.85421 0.652748C1.96532 0.652748 2.08105 0.665484 2.20142 0.690956C2.32178 0.716428 2.43983 0.760373 2.55558 0.82279L16.6181 9.8125C16.8172 9.9595 16.9688 10.1247 17.073 10.3082C17.1771 10.4916 17.2292 10.6944 17.2292 10.9167C17.2292 11.1389 17.1771 11.3417 17.073 11.5252C16.9688 11.7086 16.8172 11.8692 16.6181 12.0069L2.55558 20.9966C2.43983 21.0683 2.32178 21.1169 2.20142 21.1424C2.08105 21.1678 1.96862 21.1806 1.86412 21.1806C1.50565 21.1806 1.19332 21.0602 0.927124 20.8195C0.66093 20.5787 0.527832 20.2574 0.527832 19.8555Z" fill="white"/>
            </svg>            
            <p>{{$t('Play')}}</p>  
        </div>
        <div v-if="whereIamFrom === 'episode'" style="display: flex;align-items: center;justify-content: center;width: 100%;height:100%">
            <img src="@/assets/icons/Ic_Play1.svg" alt="" class="card-play"/>
        </div>
    </div>  
</template>
<script>
    import { vLivePlaylistItem } from "/src/vue-player/vLiveWebPlayer.js";
    import { mapActions, mapGetters, mapMutations } from "vuex";
    import Utility from "@/mixins/Utility.js";
    import PlaybackUtilities from "@/mixins/playbackUtilities.js";
    import Redirection from "@/mixins/redirection.js";
    import { eventBus } from "@/eventBus";
    import { store } from "@/store/store";
    import firebase from "firebase/app";
    import "firebase/database";
    import { showSnackBar } from "@/utilities";

    export default {
        name: "PlayButton",
        props: {
            playerInstance: {
                type: Object,
            },
            whereIamFrom: {
                type: String
            },
            content: {
                type: Object
            },
            contentId: null,
        },
        watch: {
            content(newValue, oldValue) {
                // console.log('parentValue changed from', oldValue, 'to', newValue);
                // this.renderButton();
            },
            subscriptionList(val) {
                if (Array.isArray(val) && val.length > 0) {
                    this.subscriptions = val;
                }
            },
            purchaseList (val) {
                this.purchaseListData = val;
                this.getPurchaseListCode();
            }
        },
        data() {
            return{            
                isMpegRequired: false,
                isDrmFree: false,
                buttonStatus: '',
                subscriptions: [],
                purchases: [],
                isARentalContent: false,
                userInfoDetails: [],     
                progress: null,
                watchedTime: null,
                playerPositionPerecentage: null,
                showPinModal: false,
                pinModalError: '',
                pinMode: 'restrict',
                callbackParentalLockFlag: '',
                modalLoading: false,
                forgotPinModalError: '',                
                loading: false,
                showForgotPinModal: false,
                isActiveStatus: false
            }
        },
        created() {
            try {
                this.userInfoDetails = localStorage.getItem("subscriberDetails") ? JSON.parse(localStorage.getItem("subscriberDetails")).data : [];
            } catch(err) {
                console.log(err)
            }
            this.isMpegRequired = this.getMpegRequired();
            this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
            this.localDisplayLang = this.localDisplayLang == 'ml' ? 'mal' : this.localDisplayLang;
            if (this.subscriptionList !== undefined) {
                this.subscriptions = this.subscriptionList;
            };
            if (this.purchaseList !== undefined) {
                this.purchaseListData = this.purchaseList;
                this.getPurchaseListCode();
            }
        },
        mounted() {
            
            // this.renderButton();
        },
        methods: {
            ...mapMutations(["setRegActiveDeeplink", "setAudioAndSubtitleLangState"]),
            ...mapActions([
            "getLanguagesByCodes",
            "parentalControlVerifyOldPin",
            "authorizeCurrentStreamApi",
            "setCurrentStreamApi",
            "parentalControlPinSet",
            "parentalControlResetPin",
            "availabilityDetail",
            "subscriberPlaybackExpiry"
            ]),
            async renderButton() {
                if(this.subscriberid) {
                    if(this.content.playbacktype && !(this.content.playbacktype == "LOCATORBASEDPLAYBACK")) {
                        // await this.getContentProgress(this.content);
                        await this.getButtonStatus();
                    }else if(!this.content.playbacktype) {
                        await this.getContentProgress(this.content);
                        await this.getButtonStatus();
                    }
                } else {
                    this.buttonStatus = "LOGIN & WATCH";
                }
            },
            async actPlayContent(content) {
                if(this.whereIamFrom === 'featured') {
                    eventBus.$emit('stopCarousel', true);
                }
                if(this.subscriberid) {
                    //registered or subscribed user   
                    if (!this.isContentPlayableParentalLock(this.getCurrentProfile(), this.content?.pgrating) && !this.isActiveStatus) {
                        // parental lock is Enabled
                        eventBus.$emit('event-parental-lock-CB', `play_button_${this.content.objectid}`);
                        this.isActiveStatus = true;
                        return;
                    }
                    if(this.content.category == "TVSHOW" && this.content.objecttype == "SERIES") {
                        this.redirectToDetailPage(this.content)
                    } else {
                        // console.log('this.buttonStatus', this.buttonStatus)
                        let buttonText = await this.getPlayBackAvailabilityStatus(this.content);
                        console.log('this.buttonStatus', this.buttonStatus);
                        if(this.buttonStatus === "WATCH NOW" || this.buttonStatus === "PLAY"){
                            this.content.watchedTime = await this.getContentProgress(this.content);
                            if(this.content.playbacktype && this.content.playbacktype == "LOCATORBASEDPLAYBACK"){
                                this.redirectToLocator(this.content);
                            } else if(this.content?.contentprovider == "CATCHUPTV" || this.content?.objecttype == "EPGPROGRAMME" || this.content.category == "TVCHANEL") {
                                eventBus.$emit('live-player-active', this.content);
                                return
                            } else if(this.content.playbacktype && this.content.playbacktype == "EXTERNALURLBASEDPLAYBACK"){
                                if(this.content.contentprovider === "MANORAMAMAX"){                                    
                                    let contentList = [this.content];
                                    await this.actBuyDRMToPlayerInPlay(0, contentList, contentList.length, true); 
                                    this.showPlayer();
                                    return
                                }
                            } else if(this.content.objecttype == "CONTENT") {
                                let contentList = [this.content];
                                this.loadChosenIndexContent(0, contentList, contentList.length, true);
                                this.showPlayer();
                            }
                        } else if(this.buttonStatus === "SUBSCRIBE & WATCH"){
                            this.redirectToPlans();
                        }
                    }
                } else {
                    this.redirectToLogin();
                }
                this.isActiveStatus = false;
            },
            loadChosenIndexContent(episodeIndex, episodeList, totalCount, isFirstTime = true) {
                let self = this;
                this.fetchContentStreamUrl(episodeList[episodeIndex], self.isMpegRequired)
                    .then((resp) => {
                    let loadPlayer = false;
                    
                    if (self.appConfig.featureEnabled.isConcurrencyEnabled) {
                        // Call the Stream Authorize API
                        self.authorizeCurrentStreamApi().then((authResponse) => {
                        if (!authResponse.data.errorcode) {
                            if (typeof resp == "object" && !resp.data.errorcode) {
                            self.handleFetchContentStreamUrl(
                                resp,
                                episodeList[episodeIndex]
                            );
                            loadPlayer = true;
                            } else if (typeof resp == "boolean" && resp == true) {
                            loadPlayer = true;
                            }
                            if (loadPlayer == true) {
                            self.loadContentMetaData(episodeList[episodeIndex], null);

                            let playlist = self.buildPlayerPlaylist(
                                episodeList,
                                false,
                                0
                            );
                            self.playerInstance.loadPlaylist(playlist, totalCount);

                            self.playerInstance.loadContent(episodeIndex).then(() => {
                                self.playerInstance.play().then(() => {
                                const payload = {
                                    contentId: episodeList[episodeIndex].objectid,
                                    params: {
                                    devicetype: "PC",
                                    },
                                };

                                self.setCurrentStreamApi(payload).then(
                                    (setStreamResponse) => {
                                    if (setStreamResponse.data.errorcode) {
                                        self.hidePlayer();

                                        eventBus.$emit("showAuthorizeErrorMessage", {
                                        state: true,
                                        messageData: {
                                            title: self.$t("Error"),
                                            message: self.$t("Device Limit Reached"),
                                        },
                                        });
                                    }
                                    }
                                );
                                });
                            });
                            } else {
                            let playbackError = true;

                            if (typeof resp == "object") {
                                playbackError = resp.data;
                            }

                            self.hidePlayer();

                            eventBus.$emit("showAuthorizeErrorMessage", {
                                state: true,
                                messageData: {
                                title: self.$t("Error"),
                                message: self.$t("Device Limit Reached"),
                                },
                            });

                            throw playbackError;
                            }
                        } else {
                            let playbackError = true;

                            if (typeof resp == "object") {
                            playbackError = resp.data;
                            }

                            this.hidePlayer();

                            eventBus.$emit("showAuthorizeErrorMessage", {
                            state: true,
                            messageData: {
                                title: this.$t("Error"),
                                message: this.$t("Device Limit Reached"),
                            },
                            });

                            throw playbackError;
                        }
                        });
                    }

                    // Else if Concurrency is not enabled
                    else {
                        if (typeof resp == "object" && !resp.data.errorcode) {
                        self.handleFetchContentStreamUrl(resp, episodeList[episodeIndex]);
                        loadPlayer = true;
                        } else if (typeof resp == "boolean" && resp == true) {
                        loadPlayer = true;
                        }
                        if (loadPlayer == true) {
                        self.loadContentMetaData(episodeList[episodeIndex], null);

                        let playlist = self.buildPlayerPlaylist(episodeList, false, 0);
                        self.playerInstance.loadPlaylist(playlist, totalCount);
                        self.playerInstance.loadContent(episodeIndex).then(() => {
                            self.playerInstance.play().then(() => {
                            
                            });
                        });
                        } else {
                        let playbackError = true;

                        if (typeof resp == "object") {
                            playbackError = resp.data;
                        }

                        self.hidePlayer();

                        throw playbackError;
                        }
                    }
                    })
                    .catch((error) => {
                    self.hidePlayer();
                    });
            },
            buildContentStreamRequest(contentItem, isMpegRequired) {
                let payload = {
                    contentid: contentItem.objectid,
                    params: {
                    availabilityid: contentItem.playback_details.availabilityid,
                    packageid: contentItem.playback_details.packageid,
                    },
                };

                if (isMpegRequired == true) {
                    payload.params.mpegtspackage = "YES";
                }

                return payload;
            },
            fetchContentStreamUrl(contentItem, isMpegRequired) {
                return new Promise((resolve, reject) => {
                    if (contentItem.streamUrl != undefined && contentItem.streamUrl != "") {
                    resolve(true);
                    } else {
                    resolve(
                        store.dispatch(
                        "contentStreamUrl",
                        this.buildContentStreamRequest(contentItem, isMpegRequired)
                        )
                    );
                    }
                });
            },
            handleFetchContentStreamUrl(response, contentItem) {
                contentItem.streamUrl = response.data.streamfilename;
                contentItem.scrubbing =
                    response.data.packagedfilelist &&
                    response.data.packagedfilelist.scrubbing
                    ? response.data.packagedfilelist.scrubbing
                    : "";
            },
            loadContentMetaData(contentMetaData, seriesMetaData) {
                let metaData = {
                    content: contentMetaData,
                    seriesContent: seriesMetaData,
                };

                eventBus.$emit("load-player-content", metaData);
            },
            showPlayer() {
                if (this.isARentalContent) {
                    let expiryPayload = {
                    subscriberid: this.subscriberid,
                    objectid: this.content.objectid
                    }
                    this.subscriberPlaybackExpiry(expiryPayload);
                }
                // this.playerInstance.blowUpPlayer();

                this.playerInstance.registerEvent(
                    "currentcontentended",
                    this.loadNextContentData
                );
                this.playbackInitiated = true;
                eventBus.$emit("player-visibility", true);
            },
            hidePlayer() {
                this.playerInstance.shrinkPlayer();

                eventBus.$emit("player-visibility", false);

                let payload = { content: null, seriesContent: null };
                eventBus.$emit("load-player-content", payload);

                this.playerInstance.unloadPlayer(true);
            },
            loadNextContentData(endedContentIndex) {
                this.hidePlayer();
            },
            buildPlayerPlaylist(episodeList, isTrailer, startIndex) {
                let playlist = [];
                let contentList = [this.content];
                for (var aIndex = startIndex; aIndex < episodeList.length; aIndex++) {
                    let playlistItem = this.createPlayerPlaylistItem(
                    episodeList[aIndex],
                    isTrailer,
                    contentList[0].watchedTime ? contentList[0].watchedTime : 0
                    );
                    playlist.push(playlistItem);
                }
                return playlist;
            },
            createPlayerPlaylistItem(content, isTrailer, contentHead) {
                let payload = {
                    contentId:
                    content.objectid != undefined && content.objectid != ""
                        ? content.objectid
                        : "",
                    contenttype: content.category,
                    title: content.title,
                    description: content.shortdescription,
                    thumbnail: this.extractThumbnailPoster(content),
                    source: isTrailer
                    ? [content.trailerUrl]
                    : content.streamUrl != undefined && content.streamUrl != ""
                    ? [content.streamUrl]
                    : [],
                    licenseServer: this.appConfig.providerDetails.drmLicenseUrl,
                    packageid: content.playback_details
                    ? content.playback_details.packageid
                    : "",
                    providerid: this.appConfig.providerDetails.providerId,
                    drmscheme: content.playback_details
                    ? content.playback_details?.drmscheme
                    : "",
                    availabilityid: content.playback_details
                    ? content.playback_details.availabilityid
                    : "",
                    providerSession: localStorage.getItem("sessionToken"),
                    skip:
                    !isTrailer && content.skip && Array.isArray(content.skip) && content.skip.length > 0
                        ? content.skip
                        : "",
                    position: contentHead,
                    scrubbing: content.scrubbing ? content.scrubbing[0] : "",
                    adversity: {
                    advisory: content.advisory,
                    pgrating: content.pgrating,
                    defaultgenre: content?.defaultgenre || content.genre,
                    },
                    playbacktype: content?.playbacktype || "",
                    contentprovider: content?.contentprovider || ""
                };

                payload.isDrmContent = this.isDrmFree ? true : false;

                let playlistItem = new vLivePlaylistItem(payload);

                return playlistItem;
            },
            extractThumbnailPoster(contentItem) {
                let posterKey = contentItem.poster;
                let thumbNailUrl = null;

                if (
                    posterKey &&
                    Array.isArray(posterKey) == true &&
                    posterKey.length > 0
                ) {
                    // let posterFileList = posterKey[0].filelist;

                    let posterFileList = posterKey?.find(
                    (posterKeyNode) => posterKeyNode.postertype == "LANDSCAPE"
                    )?.filelist;

                    if (
                    posterFileList &&
                    Array.isArray(posterFileList) == true &&
                    posterFileList.length > 0
                    ) {
                    let thumbNailNode = posterFileList?.find(
                        (posterNode) => posterNode.quality == "THUMBNAIL"
                    );

                    if (thumbNailNode) {
                        thumbNailUrl = thumbNailNode?.filename;
                    }
                    }
                }

                return thumbNailUrl;
            },
            getPurchaseListCode () {
                for(let list of this.purchaseListData) {
                    if ( (list.objectid).toLowerCase() == (this.content.objectid).toLowerCase()) {
                        this.rentPurchasedActive = true;
                        this.expireTxt = this.getFormatedDate(list);                
                    }  
                }
            },
            getFormatedDate(list) {
                let d = "";
                d = list.expiry
                if (list.expiryafterfirstplay) {
                    d = list.expiryafterfirstplay
                }
                function formatAMPM(date) {
                    var hours = date.getHours();
                    var minutes = date.getMinutes();
                    var ampm = hours >= 12 ? 'PM' : 'AM';
                    hours = hours % 12;
                    hours = hours ? hours : 12; // the hour '0' should be '12'
                    minutes = minutes < 10 ? '0'+minutes : minutes;
                    var strTime = hours + ':' + minutes + ' ' + ampm;
                    return strTime;
                }

                try {
                    let newDate = new Date(d);

                    newDate.setHours(newDate.getHours() + 5);
                    newDate.setMinutes(newDate.getMinutes() + 30);

                    return newDate.getDate() + " " + newDate.toLocaleString('default', {
                        month: 'short'
                    }) + " " + newDate.getFullYear() + ", " + formatAMPM(newDate);
                } catch (e) {
                    return d;
                }

            },
            async actBuyDRMToPlayerInPlay(episodeIndex, episodeList, totalCount, isFirstTime = true) {
                this.playerInstance.unloadPlayer();
                this.loadContentMetaData(episodeList[episodeIndex], null);
                let playlist = this.buildPlayerPlaylist(episodeList, false, 0);
                this.playerInstance.loadPlaylist(playlist, totalCount);
                this.playerInstance.loadContent(episodeIndex).then(() => {
                    this.playerInstance.play().then(() => {
                    
                    });
                }).catch((err) =>{alert(err)});
                return true;
            },
            getPoster() {
                let content = this.content;
                if (
                    content &&
                    content.poster &&
                    content.poster.length &&
                    !content.inwatchlist
                ) {
                    let index = content.poster.findIndex((element) => {
                    return element.postertype === "LANDSCAPE";
                    });

                    if (index > -1) {
                    if (content?.poster[index].filelist && content?.poster[index].filelist.length > 1) {
                        return content?.poster && content?.poster[index]?.filelist[1]?.filename;
                    } else {
                        return content?.poster && content?.poster[index]?.filelist[0]?.filename;
                    }
                    }
                } else if (content.inwatchlist) {
                    return content.poster && content.poster.landscape;
                } else if (content.thumbnail) {
                    return content.thumbnail;
                }
            },
            async getPlayBackAvailabilityStatus(contentItem) {
                let localStoreAvailabilityList = localStorage.getItem("availabilityList");
                localStoreAvailabilityList = localStoreAvailabilityList ? JSON.parse(localStoreAvailabilityList) : await this.fetchAvailability();
                let { filteredAvailabilityList, packageDetails } = await this.determineContentPackageAvailabilityDetails(contentItem, localStoreAvailabilityList);
                if (filteredAvailabilityList.length <= 0) {
                this.buttonStatus = null;
                }
                let contentPriceModeAvailability = [];
                const fetchAvailabilityStatus = async () => {
                const promises = filteredAvailabilityList?.map(async (contentAvailability, i) => {
                    let statusContent = await this.checkPriceModelStatus(contentItem, contentAvailability, packageDetails);
                    console.log("statusContent", statusContent);
                    return statusContent;
                });

                contentPriceModeAvailability = await Promise.all(promises);
                console.log(contentPriceModeAvailability, "contentPriceModeAvailability");
                let finalDecisionMade = false;
                contentPriceModeAvailability.forEach((item) => {
                    console.log('pp',item.buttonStatus)
                    if (!finalDecisionMade && item.isEligible) {
                    // If an eligible availability is found, set the button status and stop further checks
                    contentItem.playback_details = {
                        packageid: packageDetails?.[0]?.packageid,
                        availabilityid: item?.availabilityid,
                        drmscheme: packageDetails?.[0]?.drmscheme?.[0],
                    };
                    contentItem.videoType = "Content";
                    contentItem.isDrmContent = packageDetails?.[0]?.streamtype === 'NONE' ? true : false;
                    this.buttonStatus = item.buttonStatus;
                    finalDecisionMade = true;
                    } else if (!finalDecisionMade && !item.isEligible) {
                    // If no eligible availability is found, but there's an ineligible one, set the button status accordingly
                    contentItem.playback_details = {
                        packageid: packageDetails.packageid,
                        availabilityid: item.availabilityid,
                        drmscheme: packageDetails.drmscheme ? packageDetails.drmscheme[0] : null,
                    };
                    contentItem.videoType = "Content";
                    contentItem.isDrmContent = this.isDrmFree ? true : false;
                    this.buttonStatus = item.buttonStatus;
                    }
                });
                };
                console.log('pp',this.buttonStatus)
                await fetchAvailabilityStatus();
                // final disition
            },
            async checkPriceModelStatus(contentItem, contentAvailability, packageDetails) {
                if (contentAvailability.pricemodel == 'FREE') {
                    return { pricemodel: contentAvailability.pricemodel, availabilityid: contentAvailability.availabilityid, buttonStatus: 'PLAY', isEligible: true }
                } else if ((contentAvailability.pricemodel == "RENTAL" || contentAvailability.pricemodel == "PAID")) {
                    this.isARentalContent = true;
                    if (this.rentPurchasedActive) {
                    return { pricemodel: contentAvailability.pricemodel, availabilityid: contentAvailability.availabilityid, buttonStatus: 'PLAY', isEligible: true }
                    } else {
                    let res = await this.availabilityDetail(contentAvailability.availabilityid);
                    let userCountryCurrency = this.userInfoDetails.subscribercountry == 'IN' ? 'INR' : 'USD';
                    let currentPriceItem = {};
                    for (let Item of res.data.priceclassdetail) {
                        if (Item.currency == userCountryCurrency) {
                        currentPriceItem = Item;
                        }
                    }
                    this.rentNowPriceModel = {};
                    this.rentNowPriceModel.planname = this.content.title;
                    this.rentNowPriceModel.amount = currentPriceItem.price;
                    this.rentNowPriceModel.planid = currentPriceItem.priceclassid;
                    this.rentNowPriceModel.priceclassid = currentPriceItem.priceclassid;
                    this.rentNowPriceModel.availabilityid = contentAvailability.availabilityid;
                    this.rentNowPriceModel.planinterval = res.data.licenseduration;
                    this.rentNowPriceModel.currency = currentPriceItem.currency;
                    this.rentNowPriceModel.rental = "PURCHASE";
                    this.rentNowPriceModel.getPoster = this.getPoster();
                    this.rentNowPriceModel.objectid = this.content.objectid
                    let buttonStatus = contentAvailability.pricemodel == "RENTAL" ? "RENT NOW" : "PURCHASE NOW";
                    return { pricemodel: contentAvailability.pricemodel, availabilityid: contentAvailability.availabilityid, buttonStatus: buttonStatus, isEligible: false }
                    }
                } else if (contentAvailability.pricemodel === "PLAN") {
                    if (this.subscriberid && this.subscriptions.length > 0) {
                    let isMatched = false;
                    this.subscriptions.every((subscription) => {
                        // Check if next billing date is greater than today's date.
                        if (
                        this.getDateForPlayback(subscription.nextbilling) <
                        this.getTodaysDate()
                        ) {
                        isMatched = { buttonStatus: "SUBSCRIBE & WATCH", isEligible: false };
                        return false;
                        } else {
                        let getValue = subscription?.availabilityset?.includes(contentAvailability.availabilityid);
                        if (getValue) {
                            isMatched = { pricemodel: contentAvailability.pricemodel, availabilityid: contentAvailability.availabilityid, buttonStatus: 'PLAY', isEligible: true }
                            return false;
                        } else {
                            isMatched = { pricemodel: contentAvailability.pricemodel, availabilityid: contentAvailability.availabilityid, buttonStatus: 'SUBSCRIBE & WATCH', isEligible: false }
                            return true;
                        }
                        }
                    });
                    return isMatched;
                    } else if (this.subscriberid && this.subscriptions.length === 0) {
                    return { pricemodel: contentAvailability.pricemodel, availabilityid: contentAvailability.availabilityid, buttonStatus: "SUBSCRIBE & WATCH", isEligible: false }
                    }
                }
            },
        },
        computed: {
            ...mapGetters([
                "appConfig",
                "subscriberid",
                "availabilityList",
                "subscriptionList",
                "purchaseList",
                "getAudioAndSubtitleLangState",
                "getAllLanguageFullForm",
                "appConfig",
                "profileid",
                "getSubscriptionInformation"
            ]),
        },
        components: {
            PinModal: () => import("@/components/utils/PinModal.vue"),
            ForgotPinModal: () => import("@/components/utils/ForgotPinModal.vue"),
        },
        mixins: [PlaybackUtilities, Utility, Redirection],
        beforeDestroy() {
            // alert('beforeDestroy')
        }
    }
</script>
<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
    .card-play{
        width:36px !important;
        height: 36px !important;
    }
    .featured_play{
        background: $primary-btn-color;
        width: 6rem;  color: #FFFFFF;
        // padding: 0.4rem;
        border-radius: 0.2rem;
        font-size: 0.8rem;
        font-weight: 500;
        text-align: center;
        margin-top: 0rem;
        cursor: pointer;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          //transform: translate(-5px);
          transform: scale(1.1);
          box-shadow: 0px 0px 1px 1px rgba(31, 31, 31, 0.425);
        }
      }
</style>